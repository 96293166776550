import React from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { ILicenseInfoDetails } from 'interfaces/public/license';
import i18n from 'translations';
import moment from 'moment';

export const detailColumns: ColumnDescription<any, ILicenseInfoDetails>[] = [
  {
    dataField: 'code',
    text: i18n.t('licenseContent.detailColumns.code'),
    sort: true,
  },
  {
    dataField: 'name',
    text: i18n.t('licenseContent.columns.product'),
    sort: true,
  },
  {
    dataField: 'licenseExp',
    text: i18n.t('licenseContent.detailColumns.expiryDate'),
    style: {
      width: '200px',
    },
    formatter: (cell: any, row: ILicenseInfoDetails) => {
      return moment(row.licenseExp).format(i18n.t('formats.date.short'));
    },
  },
  {
    dataField: 'featureMapBinarySetBits',
    text: i18n.t('licenseContent.detailColumns.featureMap'),
    sort: true,
  },
  {
    dataField: 'permissions',
    text: i18n.t('licenseContent.detailColumns.permissions'),
    sort: true,
  },
  {
    dataField: 'information',
    text: i18n.t('licenseContent.detailColumns.information'),
    sort: true,
  },
  {
    dataField: 'formerDongleNo',
    text: i18n.t('licenseContent.detailColumns.formalDongle'),
    sort: true,
    hidden:true,
  },
  {
    dataField: 'maintenanceExp',
    text: i18n.t('licenseContent.detailColumns.endOfMaintenance'),
    style: {
      width: '200px',
    },
    formatter: (cell: any, row: ILicenseInfoDetails) => {
      
      return row.maintenanceExp !== "--" ? moment(row.maintenanceExp).format(i18n.t('formats.date.short')) : row.maintenanceExp;
    },
  },
];
