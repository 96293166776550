export default {
  formats: {
    date: {
      short: 'DD.MM.YYYY',
      long: 'DD.MM.YYYY HH:mm',
      describe: 'MMMM Do YYYY, h:mm A',
    },
  },
  language: {
    de: 'Tedesco',
    en: 'Inglese',
    it: 'Italiano',
    fr: 'Francese',
    sp: 'Spagnolo',
    pl: 'Polacco',
  },
  search: 'Cerca',
  goBack: 'Indietro',
  signIn: {
    title: 'Accedi',
    dontHaveAccount: 'Non hai un account?',
  },
  signUp: {
    title: 'Registrati',
    info:
      'Registrandoti accetti i nostri  <0>{{privacy}}</0> e <1>{{terms}}</1>.',
    promotions: 'Non voglio ricevere Email di aggiornamento o promozioni',
    alreadyHaveAccount: 'Hai già un account?7',
    serviceterms: 'Accetto <0>{{service}}</0>.',
    dataprocessing: 'Accetto <0>{{agreement}}</0>.',
  },
  signOut: {
    title: 'Sign out',
  },
  email: {
    label: 'Indirizzo Email',
    placeholder: 'Indirizzo Email',
    taken: 'La Email è già stata utilizzata',
  },
  password: {
    label: 'Password',
    placeholder: 'Inserire password',
    mismatch: 'Le passowrd devono combaciare',
  },
  passwordConfirmation: {
    label: 'Confermare password',
    placeholder: 'Inserire conferma password',
  },
  firstName: {
    label: 'Nome',
    placeholder: 'Inserire Nome',
  },
  lastName: {
    label: 'Cognome',
    placeholder: 'Inserire Cognome',
  },
  activationKey: {
    label: 'ID-TICKET',
    placeholder: 'Inserire ID-TICKET',
  },
  companyName: {
    label: 'Nome Compagnia',
    placeholder: 'Inserire Nome Compagnia',
  },
  contactPerson: {
    label: 'Persona di riferimento',
    placeholder: 'Inserire nome della Persona di riferimento',
  },
  country: {
    label: 'Stato',
  },
  address: {
    label: 'Indirizzo',
    placeholder: 'Inserire Indirizzo',
  },
  city: {
    label: 'Città',
    placeholder: 'Inserire Città',
  },
  zip: {
    label: 'Codice Postale',
    placeholder: 'Inserire Codice Postale',
  },
  web: {
    label: 'Pagina Web',
    placeholder: 'Inserire pagina Web',
  },
  uid: {
    label: 'P. IVA',
    placeholder: 'Inserire P. IVA',
  },
  vatId: {
    label: 'Regione',
    placeholder: 'Es. E1234567890',
  },
  state: {
    label: 'Procincia',
    placeholder: 'Inserire Provincia',
  },
  street: {
    label: 'Via',
    placeholder: 'Inserire via',
  },
  phone: {
    label: 'Telefono',
    placeholder: 'Inserire Telefono',
  },
  forgot: {
    title: 'Password dimenticata',
    info:
      'Non preoccuparti! Inserisci la tua Email e ti invieremo un link per ripristinare la tua password.',
    button: 'Invia link per ripristinare password.',
    password: 'Password dimenticata?',
  },
  header: {
    profile: 'Profilo',
    settings: 'Impostazioni',
    companyInfo: 'Informazioni Compagnia',
    selectMill: 'Seleziona Centro di Produzione',
  },
  footer: {
    menu: {
      aboutUs: {
        title: 'About us',
        content:
          "Follow-Me! Technology Group sviluppa e distribuisce hyperDENT®, Un software per i processi di produzione CAM. La casa madre di FOLLOW-ME! Technology GmbH è presos Monaco di Baviera dove il nostro CEO Dr. Ferdinand Schwenkert e CTO Günter Linseis, responsabile dell'innovazione e dello sviluppo delle novità del prodotto, continuano a guardare al futuro del settore.  <br> <br> Gli altri uffici di FOLLOW-ME! Technology Group sono distribuiti in tutto il mondo come in Italia, Cina, Giappone, Corea, Singapore e negli USA.",
        ceoMessage:
          '“Vogliamo fornire un software dentale per creare un valore aggiunto al processo di digitalizzazione ed automatizzazione.”',
      },
      contact: {
        title: 'Contratto',
        content:
          '<strong>FOLLOW-ME!South Europe TECHNOLOGY srl, via Martiri di Belfiore,1 20017 RHO (MI) <br> Tel.: +39 029307814 . <br> E-Mail: info.see@fm-dental.com</strong>',
      },
      desktopApp: {
        title: 'Desktop App',
        content: "<strong>Scarica l'App Desktop qui</strong>",
      },
      privacyPolicy: {
        title: 'Privacy policy',
        content:
          '<strong>Data protection is a matter of very high importance for FOLLOW-ME! Technology GmbH. Use of our website is generally possible without the need to provide personal data. Personal data is only processed to the extent necessary for the ability to provide a functioning website and the content and services offered through it. The processing of personal data of our users normally only takes place with the userâ€™s prior consent. An exception to this exists in cases where it is not possible to obtain consent in advance due to reasons of fact and where processing of the data is permitted under the provisions of law.</strong>',
      },
      termsOfService: {
        title: 'Termini del Servizio',
        content:
          "<strong>Stiamo lavorando per aggiornare i termini di servizio, controllare più tardi i termini e condizioni aggiornati per l'utilizzo di hyperDENT Connect.</strong>",
      },
      dataProcessing: {
        title: 'Accordo gestione dati',
        content:
          "<strong>Stiamo lavorando per aggiornare i termini di servizio, controllare più tardi i termini e condizioni aggiornati per l'utilizzo di hyperDENT Connect.</strong>",
      },
    },
  },
  home: {
    home: 'Home',
    subtitle: 'hyperDENT Connect',
    title: 'Connettiti con gli Expert',
    content: 'Condividi i tuoi lavori nel modo più semplice',
    reasons: {
      title: 'Perchè usare hyperDENT Connect?',
      reason1: {
        title: "L'odontoiatria digitale sta modificanto i requisiti. ",
        content:
          'Maggiore produttività, maggiore flessibilità, maggiore spazio e processi più lineari.',
      },
      reason2: {
        title: 'Personalizza i tuoi servizi cloud',
        content:
          'Il nuovo servizio cloud completamente modulabile, personalizzabile e dalle alte prestazioni permette ai Centri di Produzione di aprirsi a nuove possibilità e di raggiungere ulteriori traguardi.',
      },
      reason3: {
        title: 'Semplice accesso al cloud ',
        content:
          "hyperDENT Connect consente l'accesso ai file dei tuoi clienti tamite un app disponibile da ogni device connesso ad Internet",
      },
    },
    policiesupdate: {
      title: 'Policy Update(s)',
      content:
        'Acconsenti al trattamento dei termini di utilizzo ed ai dati personali.',
    },
  },
  welcome: {
    title: 'Benvenuto in hyperDENT Connect',
    content:
      'hyperDENT Connect è una piattaforma per la condivisione dei file dentali. Permette uno scambio tra i clienti ed il centro di produzione in tempo reale ed uno strumento di comunicazione semplice ed immediato. ',
    landingPage: {
      title: '1-Crea la tua pagina',
      content: '',
      button: 'Impostazioni della tua Pagina',
    },
    inviteClients: {
      title: '2. Invita i clienti',
      content:
        'Aggiungi i laboratori o gli studi ai tuoi clienti invitandoli con il link preimpostato. ',
      button: 'Invita i clienti',
    },
  },
  labWelcome: {
    title: 'Istruzioni',
    content: 'Titolo: testo qui',
    serviceApp: {
      title: 'Servizio App',
      content: '',
      button: "Scarica l'App",
    },
    dashboard: {
      title: 'Dashbord',
      content: '',
      button: 'Vai al Pannello di Controllo',
    },
  },
  orders: {
    title: 'Ordini',
    deleted: 'Cancellato',
    deletedFilesInfo:
      'I file per questo progetto sono stati cancellati perchè la durata di conservazione dei file è scaduta.',
    noSearch: 'Nessun risultato della ricerca',
    noOrders: 'Nessun ordine creato',
    winApp: "Gli ordini sono creati dall'applicazione",
    downloadApp: 'Scarica App',
    columns: {
      orderId: 'ID Ordine',
      name: 'Nome Ordine',
      date: 'Data',
      messages: 'Messaggi',
      components: 'Componenti',
      client: 'Clienti',
      responsible: 'Responsabile Progetto',
      download: 'Download',
      report: 'Report',
    },
    component: {
      columns: {
        name: 'Componente',
        status: 'Stato',
        messages: 'Messaggi',
        files: 'Files',
        comment: 'Commento',
      },
    },
    messages: 'messaggi',
    message: 'messaggio',
    newMessage: 'nuovo',
    select: {
      any: {
        client: 'Qualsiasi Cliente',
        responsible: 'Qualsiasi Utente',
      },
    },
  },
  project: {
    download: {
      flat: 'Piano',
      hierarchical: 'Gerarchica',
    },
  },
  component: {
    orders: 'Ordini',
    component: 'Componente',
    status: {
      label: 'Stato del Componente',
      any: 'Qualsiasi Stato',
      open: 'Aperto',
      closed: 'Chiuso',
      downloaded: 'Scaricato',
    },
    orderId: 'ID Ordine',
    project: 'Progetto',
    preview: 'Preview Componente',
    documents: 'Documenti Componente',
    downloadAll: 'Scarica tutti',
    downloads: 'Documenti da scaricare',
    uploadPdf: 'Carica Pdf',
    noFiles: 'No File',
    messages: {
      title: 'Messaggi',
      message: {
        placeholder: 'Scrivi un messaggio',
      },
      button: 'Manda un messaggio',
    },
    upload: {
      title: 'Aggiungi un documento',
      name: {
        label: 'Nome documento',
        placeholder: 'Inserirsci nome documento',
      },
      button: 'Carica',
    },
    download: {
      deleteConfirm: {
        title: 'Cancella documento',
        message: 'Sei sicuro di voler cancellare il documento',
        buttonOk: 'Cancella',
        buttonCancel: 'Cancella',
      },
      deleteToast: {
        title: 'Cancella documento',
        message: 'Documento cancellato',
      },
      uploadToast: {
        title: 'Carica documento',
        message: 'Documento caricato',
      },
    },
    updateToast: {
      title: 'Carica Componente',
      message: 'Componente caricato con successo',
    },
    messageCreateToast: {
      title: 'Manda un messaggio',
      message: 'Messaggio inviato',
    },
    updateOrderToast: {
      title: 'Aggiorna Ordine',
      message: "L'Orine è stato aggiornato con successo",
    },
  },
  fileUpload: {
    label: 'Drag & drop o clicca per caricare',
  },
  clients: {
    title: 'Clienti',
    addClient: 'Aggiungi Cliente',
    status: {
      label: 'Stato',
      any: 'Qualsiasi Stato',
      active: 'Attivo',
      inactive: 'Inattivo',
    },
    columns: {
      company: 'Compagnia',
      email: 'Email',
      lab: 'Nome Laboratorio',
      contactPerson: 'Persona di Riferimento',
      status: 'Stato',
    },
    actions: {
      sendInvite: 'Invia Invito',
      delete: 'Cancella',
      blocked: 'Blocca',
      unBlock: 'Sblocca',
    },
    deleteConfirm: {
      title: 'Cancella Cliente',
      message: 'Sei sicuro di voler cancellare il cliente?',
      buttonOk: 'Cancella',
      buttonCancel: 'Cancella',
    },
    blockedConfirm: {
      title: 'Blocca cliente',
      message: 'Sei sicuro di bloccare il cliente?',
      buttonOk: 'Blocca',
      buttonCancel: 'Cancella',
    },
    unblockedConfirm: {
      title: 'Sblocca cliente',
      message: 'Sei sicuro di sbloccare il cliente?',
      buttonOk: 'Sblocca',
      buttonCancel: 'Cancella',
    },
    deleteToast: {
      title: 'Cancella cliente',
      message: 'Cliente cancellato',
    },
    blockToast: {
      title: 'Blocca cliente',
      message: 'Cliente bloccato',
    },
    unblockToast: {
      title: 'Sblocca cliente',
      message: 'Cliente sbloccato',
    },
    blockNotAllowedToast: {
      title: 'Sblocca cliente',
      message:
        'Il cliente è bloccato dal sistema, contatta il tuo amministratore',
    },
    client: {
      title: 'Aggiungi cliente',
      button: 'Aggiungi Cliente',
    },
    activation: {
      title: 'Istruzioni di attivazione',
      info:
        'Cliente aggiunto con successo. Puoi copiare e usare questo messaggio per contattare il cliente appena aggiunto.',
      message: {
        label: 'Messaggio di Invito',
        placeholder: 'Inserire Messaggio di Invito',
      },
      copy: 'Copia messaggio',
      send: 'Invia Email',
      confirm: {
        title: 'Email di Istruzioni',
        message: 'Email di Istruzioni è stata inviata al cliente',
        buttonOk: 'Chiudi',
      },
      errorConfirm: {
        title: 'Errore di attivazione',
        buttonOk: 'Ok',
      },
    },
    millingcenterClientAdded: {
      title: 'Centro di Produzione Aggiunto',
      message:
        "Il cliente del Centro di Produzione '{{millClient}}' è stato aggiunto",
      buttonOk: 'Bene',
    },
  },
  messages: {
    title: 'Messaggi',
    status: {
      any: 'Qualsiasi Messaggio',
      read: 'Letto',
      unread: 'Non Letto',
    },
    noMessages: 'Non hai messaggi',
  },
  settings: {
    title: 'Impostazioni',
    tabs: {
      landingPage: 'Pagina Principale',
      companyDetails: 'Informazioni Compagnia',
      users: 'Utenti',
      licenseKeys: 'Chiavi di licenza',
    },
    company: {
      logo: {
        title: 'Logo Compagnia',
        label: 'Logo',
      },
      info: {
        title: 'Info Compagnia',
      },
      button: 'Update',
      message: {
        update: 'Prego, aggiorna le tue Informazioni Compagnia',
      },
      error: {
        title: 'Errore',
        message:
          'Si è verificato un errore durante il salvataggio della pagina.',
      },
    },
    users: {
      add: 'Aggiungi Utente',
      edit: 'Modifica Utente',
      roles: {
        title: 'Ruoli',
        all: 'Tutti i Ruoli',
        admin: 'Amministratore',
        user: 'Utente',
      },
      columns: {
        name: 'Nome',
        email: 'Email',
        role: 'Ruolo',
      },
      actions: {
        delete: 'Cancella',
        edit: 'Modifica',
      },
      deleteConfirm: {
        title: 'Cancella utente',
        message: "Sei sicuro di cancellare l'utente?",
        buttonOk: 'Cancella',
        buttonCancel: 'Cancella',
      },
      addToast: {
        title: 'Aggiungi utente',
        message: 'Utente creato',
      },
      editToast: {
        title: 'Modifica Utente',
        message: 'Utente aggiornato',
      },
      deleteToast: {
        title: 'Cancella utente',
        message: 'Utente cancellato',
      },
      error: {
        title: 'Errore',
        message:
          'Si è verificato un errore mentre si salvavano i dettagli utente',
      },
    },
    licenseKey: {
      add: 'Aggiungi chiave di licenza',
      columns: {
        licenseKey: 'Chiave di Licenza',
        placeholder: 'Inserisci Chiave di Licenza',
        enableCollection: 'Abilita raccolta dati',
      },
      actions: {
        delete: 'Cancella',
      },
      deleteConfirm: {
        title: 'Cancella Chiave di Licenza',
        message: 'Sei sicuro di voler cancellare la Chiave di Licenza?',
        buttonOk: 'Cancella',
        buttonCancel: 'Cancella',
      },
      addToast: {
        title: 'Aggiungi chiave di licenza',
        message: 'Chiave di licenza aggiunta',
      },
      editToast: {
        title: 'Modifica chiave di licenza',
        message: 'Chiave di licenza modificata',
      },
      deleteToast: {
        title: 'Cancella Chiave di licenza',
        message: 'Chiave di Licenza cancellata',
      },
      error: {
        title: 'Errore',
        message: 'Errore durante invio chiave di licenza',
      },
    },
    landingPage: {
      intro: 'Crea la tua Pagina Principale',
      copy: {
        label: 'Copia url',
        button: 'Copia',
      },
      logo: 'Logo',
      header: {
        label: 'Titolo',
        placeholder: 'Scrivi Titolo',
      },
      content: {
        label: 'Contenuti',
        placeholder: 'Scrivi una descrizione',
      },
      update: 'Update',
      preview: {
        btnTxt: 'Mostra Pagina Principale',
        closeBtnTxt: 'Chiudi Preview',
        signIn: 'Accedi',
      },
      logoAlt: 'Logo Compagnia',
      notify: {
        title: 'Aggiorna pagina principale',
        message: 'La pagina principale non è stata rinnovata. Vuoi farlo ora?',
        okText: 'Procedi',
        cancelText: 'Salta per ora',
      },
    },
  },
  imageUpload: {
    button: 'Browse',
  },
  countries: {
    empty: 'Seleziona una Nazione',
    de: 'Germania',
    us: "Stati Uniti d'America",
    es: 'Spagna',
  },
  support: {
    title: 'Supporto',
    tabs: {
      contact: 'Contatto',
      downloadApp: "Scarica l'App",
    },
    contact: {
      info: 'Come possiamo aiutarti?',
      title: 'Scrivici',
      topic: {
        label: 'Argomento',
        placeholder: 'Di cosa vuoi parlare?',
      },
      topics: {
        empty: 'Scegli un Argomento',
        topicA: 'Argomento A',
        topicB: 'Argomento B',
        topicC: 'Argomento C',
      },
      message: {
        label: 'Messaggio',
      },
      submit: 'Invia',
      callUs: 'Chiamaci',
      line: 'Numero di Telefono del Supporto',
      phone: 'Tel.: +49 89 452 170 60',
      fax: 'Fax.: +49 89 452 170 69',
      sendEmailToast: {
        title: 'Email di conttato',
      },
    },
    downloadApp: {
      info: 'Applicazione Desktop per il tuo PC',
      download: 'Download',
      toastTitle: "Scarica l'App",
      notFound: "L'App non è disponibile",
    },
  },
  account: {
    title: 'Account',
    tabs: {
      company: 'Informazioni Compagnia',
      settings: 'Impostazioni',
      profile: 'Profilo',
    },
    company: {
      companyInfo: 'Gestisci le informazioni della tua Compagnia',
      logo: 'Logo Compagnia',
      information: 'Info',
      contact: 'Contatto',
      address: 'Indirizzo',
      shipping: {
        title: 'Spedizione',
        address: 'Indirizzo di Spedizione',
        sameAsAddress: 'Stesso Indirizzo',
        street: {
          label: 'Via',
          placeholder: 'Inserisci via di spedizione',
        },
        city: {
          label: 'Città',
          placeholder: 'Inserisci città di spedizione',
        },
        zip: {
          label: 'Codice Postale',
          placeholder: 'Inserisci Codice Postale',
        },
      },
      button: 'Update',
    },
    settings: {
      info: 'Modifica i tuoi dati personali',
      password: {
        title: 'Password',
        label: 'Nuova password',
        placeholder: 'Scrivi nuova password',
      },
      passwordConfirmation: {
        label: 'Conferma nuova password',
        placeholder: 'Scrivi e conferma password',
      },
      updatePassword: 'Aggiorna password',
      notifications: {
        title: 'Notifiche',
        notificationOnNewMessageReceived: 'Nuovo Messaggio ricevuto',
        sendEmailOnNewMessageReceived: 'Email su nuovo messaggio',
        notificationOnNewProjectIsCreated: 'Nuovo ordine creato',
        sendEmailOnNewProjectIsCreated: 'Emeil su nuovo ordine',
        notificationOnOrderProcessStart: "Processo d'Ordine iniziato",
        notificationOnOrderProcessCompleted: "Processo d'Ordine completato",
      },
      language: {
        title: 'Lingua',
      },
      project: {
        title: 'Progetto',
        folderStructure: 'Scarica struttura cartella',
      },
    },
    profile: {
      info: 'Imposta le tue informazioni del Profilo',
      button: 'Update',
    },
    updateProfileToast: {
      title: 'Aggiorna profilo',
      message: 'Profilo aggiornato',
    },
    updatePasswordToast: {
      title: 'Aggiorna password',
      message: 'Password aggiornata',
    },
    updateNotificationToast: {
      title: 'Nuova notifica',
      message: 'Notifica aggiornata',
    },
    updateLanguageToast: {
      title: 'Aggiorna lingua',
      message: 'Lingua aggiornata',
    },
    updateCompanyToast: {
      title: 'Aggiorna compagnia',
      message: 'Compagnia aggiornata',
    },
    invalidVAT: {
      title: 'VAT Invalido',
      message: 'Il codice VAT non è corretto, prego inserirne uno valido.',
    },
  },
  switch: {
    on: 'On',
    off: 'Off',
  },
  auth: {
    registerConfirm: {
      title: 'Link di conferma',
      message:
        "Vi abbiamo inviato un link di conferma per email. Prego controlla la mail per attivare l'account",
      buttonOk: 'OK',
    },
    confirmationConfirm: {
      title: 'Registrazione',
      message:
        'Benvenuto {{name}}! Il tuo account è attivo. Puoi usare la piattaforma.',
      buttonOk: 'Bene',
    },
    forgotConfirm: {
      title: 'Dimenticata conferma password',
      message: 'Per ripristinare la password controlla la tua email.',
    },
    resetConfirm: {
      title: 'Reimposta la password di conferma',
      message: 'La tua password è stata cambiata',
    },
    accountCreationConfirm: {
      title: 'Registrazione con successo',
      error: 'Errore durante la Registrazione',
      message:
        'Benvenuto {{name}}! Il tuo account è attivo. Puoi usare la piattaforma.',
      buttonOk: 'Bene',
    },
    millCenterAdded: {
      title: 'Centro di Produzione aggiunto',
      message: "Ti sei unito a '{{millName}}' .",
      buttonOk: 'Bene',
    },
  },
  reset: {
    title: 'Reset password',
    info: 'Prego, inserisci la tua nuova password',
    button: 'Cambia la tua password',
  },
  accountCreation: {
    title: 'Creazione account',
    info: "Pre completare la creazione dell'Account inserisci una password.",
    password: {
      label: 'Crea password',
      placeholder: 'Inserisci password',
    },
    button: 'Attiva Account',
  },
  invitation: {
    title: 'Invito',
    emailLabel: 'Email',
    message: "Sei stato invitato da '{{millName}}' .",
    submitBtnText: 'Crea un nuovo Account',
    connectToAccount: 'Unisciti ad un Account esistente',
    connectToAccountMsg:
      'Sei hai un Account hyperDENT Connect esistente puoi collegarlo.',
    or: 'o',
    addMillToAccountTxt:
      'Aggiungi questo Centro di Produzione a questo Account',
    createNewAccount: 'Crea un nuovo Account',
    sendConfirmationEmail: 'Invia email di conferma',
    existingAccount: 'Account Esistente',
    existingAccountMsg:
      "L'Email che hai utilizzato è già associata ad un Account hyperDENT Connect. Puoi aggiungere un nuovo account usando una email differente.",
  },
  landingPage: {
    signIn: 'Accedi',
  },
  unsavedChanges: {
    message: 'Hai dei cambiamenti non salvati, vuoi uscire?',
  },
  contract: {
    title: 'Contratto',
    error: 'Errore',
    tabs: {
      contractType: {
        title: 'Tipo di contratto',
        subscribe: 'Sottoscrivi',
        unsubscribe: 'Elimina Sottoscrizione',
        resubscribe: 'Sottoscrivi nuovamente',
        addOnConfirmation:
          'I file sono mantenuti per {{addOnDuration}} giorni (â‚¬{{getPromotionalPrice}}/month) è aggiunto al tuo contratto e saranno conteggiati nel prossimo pagamento.',
        addOnUnsubscription:
          'Il numero di file mantenuti per la durata è selezionato nel tuo piano',
        subscriptionSuccess: 'Hai sottoscritto il piano da te selezionato',
        subscriptionFailure:
          'Un errore è avvenuto durante la sottoscrizione al piano selezionato. Prego contattare un amministratore o riprovare più tardi.',
        unsubscriptionSuccess:
          'Hai rimosso la sottoscrizione al piano selezionato',
        unsubscriptionFailure:
          'Un errore è avvenuto durante la rimozione della sottoscrizione al piano selezionato. Prego contattare un amministratore o riprovare più tardi.',
        resubscriptionSuccess: 'Hai ripristinato il tuo piano',
        resubscriptionFailure:
          'Un errore è avvenuto mentre ripristinavi il tuo piano, contatta il tuo amministratore.',
        month: 'Mese',
        cancelsAt: 'Sottoscrizione termina il: ',
        free: 'Gratis',
        silver: 'Argento',
        gold: 'Oro',
        basic: 'Basic',
        essential: 'Essential',
        enterprise: 'Enterprise',
        custom: 'Custom',
        storage: '{{dataLimit}}Mbs di spazio Cloud',
        orders: 'Crea fino a {{ordersLimit}} ordini',
        clients: 'Invita fino a {{clients}} clienti',
        supportAvailable: 'Supporto disponibile',
        supportNotAvailable: 'Supporto non disponibile',
        cancel: 'Cancella',
        confirm: 'Conferma',
        upgrade: 'Upgrade',
        cancelConfirmation:
          'Sei sicuro di voler cancellare la tua sottoscrizione?',
        resubscriptionConfirmation:
          'Sei sicuro di voler riprendere la sottoscrizione?',
        subscriptionConfirmation:
          'La tua sottoscrizione verrà aggiornata. Confermi?',
        upgradesubscriptionConfirmation:
          'Sei sicuro di volere cambiare la tua sottoscrizione?',
        subscriptionConsent:
          'Grazie per aver scelto la sottoscrizione per hyperDENT Connect, conferma per continuare.',
        payment: {
          success:
            'Il tuo pagamento è stato ricevuto. Verrai ridiretto a breve.',
          failure:
            'Il pagamento non è stato confermato a causa di una interruzione manuale del processo o a dati invalidi della carta di credito. Verrai ridiretto a breve.',
        },
      },
      usageDetail: {
        title: 'Dettagli di utilizzo',
        resourceName: 'Nome risorsa',
        used: 'Usato',
        limit: 'Limite',
        usagePercentage: 'Percentuale Usata',
        errorMessage: 'Un errore è avvenuto mentre si caricavano i dettagli',
      },
      billing: {
        title: 'Fatturazione',
        custEmail: 'Email cliente',
        custComp: 'Nome Compagnia',
        fromPeriod: 'Periodo dal',
        toPeriod: 'Periodo fino a',
        amount: 'Numero',
        errorMessage:
          'Errore avvenuto durante il caricamento dei dati di fatturazione',
        manage: 'Gestisci fatturazione',
      },
      invoices: {
        title: 'Invoices',
        id: 'ID Fattura',
        itemDesc: 'Descrizione',
        amountDue: 'Importo dovuto',
        download: 'Download',
        isPaid: 'è pagato',
        paid: 'Pagato',
        due: 'Dovuto',
        errorMessage:
          'Un errore è avvenuto mentre si caricava la lista invoices.',
        totalAmountDue: 'Totale dovuto',
        dateDue: 'Data',
        upcoming: 'Successivo invoice',
        buttonOk: 'OK',
      },
    },
    addons: {
      title: 'Add-Ons',
      keepFiles: 'Durata della conservazione dei file',
      days: 'giorni',
      month: 'mese',
    },
  },
  errorBoundary: {
    errorMessage: 'Oops !, qualcosa non ha funzionato',
    errorFix: 'Errore tecnico, stiamo lavorando per ripristinarlo',
  },
  notifications: {
    label: 'Notifiche',
    new: 'Nuova Notifica',
    none: 'Non hai nuove notifiche',
    markAsUnread: 'Segna come non Letto',
    filter: {
      all: 'Tutto',
      unread: 'Non letto',
    },
  },
  reports: {
    download: {
      error: {
        title: 'Errore',
        message: 'Qualcosa è andato male mentre si creava il PDF di report',
      },
      success: {
        title: 'Successo',
        message: 'PDF generato',
      },
    },
    dateFormat: 'Formato data del Report',
  },
  licenseContent: {
    heading: 'License Content',
    placeholder: 'Search License Key',
    licenses: 'Licenses',
    products: 'Products',
    format: 'Format',
    invalid: 'Invalid Key Format',
    detail: 'License Details',
    columns: {
      ticket: 'Ticket',
      product: 'Product',
      licenseExpiry: 'License Expiration',
      maintenanceExpiry: 'Maintenance Expiry',
    },
    detailColumns: {
      code: 'Code',
      expiryDate: 'Expiration Date',
      featureMap: 'Feature Map',
      permissions: 'Permissions',
      information: 'Information',
      formalDongle: 'Former Dongle No.',
      endOfMaintenance: 'END of Maintenance',
      count: 'Count',
      productCode: 'Product Code',
    },
    tabActive: 'Active Licenses',
    tabOrdered: 'Ordered Licenses',
    tabDetails: 'Active Product Details',
  },
  dealer: {
    save: {
      title: 'Dealer Saved',
      message: 'Dealer data has been saved successfully',
    },
    update: {
      title: 'Update',
      message: 'Dealer data has been updated successfully',
    },
  },
};
