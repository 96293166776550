import React from 'react';
import { ColumnDescription } from 'react-bootstrap-table-next';
import { ILicenseInfo } from 'interfaces/public/license';
import i18n from 'translations';
import moment from 'moment';

export const columns: ColumnDescription<any, ILicenseInfo>[] = [
  {
    dataField: 'ticketId',
    text: i18n.t('licenseContent.columns.ticket'),
    sort: true,
  },
  {
    dataField: 'productName',
    text: i18n.t('licenseContent.columns.product'),
    sort: true,
  },
  {
    dataField: 'licenseExp',
    text: i18n.t('licenseContent.columns.licenseExpiry'),
    style: {
      width: '200px',
    },
    formatter: (cell: any, row: ILicenseInfo) => {
      return moment(row.licenseExp).format(i18n.t('formats.date.short'));
    },
  },
  {
    dataField: 'maintenanceExp',
    text: i18n.t('licenseContent.columns.maintenanceExpiry'),
    style: {
      width: '200px',
    },
    formatter: (cell: any, row: ILicenseInfo) => {
      return moment(row.maintenanceExp).format(i18n.t('formats.date.short'));
    },
  },
];
